import React, { type FC } from 'react';
import css from 'bb/discovery/start/start.module.scss';
import { LimitaryText } from 'bb/i18n';

export const PottermoreCopyright: FC = () => (
    <div className={css.copyright}>
        <LimitaryText size="small" color="primary-black">
            © J.K. Rowling/Pottermore Ltd. TM WBEI.
        </LimitaryText>
    </div>
);
